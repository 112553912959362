<template>
  <div class="bgStyle">
    <div class="head">销售订单<span>详情</span></div>
    <div class="oper">
      <a-space>
        <a-button type="primary" @click="save">保存</a-button>
        <a-button @click="back">返回</a-button>
      </a-space>
    </div>

    <div class="basic">
      <div class="tit">基本信息</div>
      <a-form-model ref="basicForm" :model="formData" :rules="rules">
        <div class="info">
          <a-form-model-item label="订单编号" prop="salesNo">
            <a-input
              v-model="formData.salesNo"
              :disabled="true"
              style="width: 50%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="外部单号" prop="sourceNo">
            <a-input v-model="formData.sourceNo" style="width: 50%"></a-input>
          </a-form-model-item>
          <a-form-model-item label="订单来源" prop="sourceType">
            <a-select
              style="width: 50%"
              ref="select"
              v-model="formData.sourceType"
              placeholder="请选择订单来源"
              allowClear
            >
              <a-select-option
                v-for="item in orderSource"
                :value="item.codeName"
                :key="item.codeName"
                >{{ item.codeName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="下单日期" prop="orderDate">
            <a-date-picker
              v-model="formData.orderDate"
              style="width: 50%"
              valueFormat="YYYY-MM-DD"
            />
          </a-form-model-item>
          <a-form-model-item label="销售人员" prop="salesUserId">
            <a-select
              :value="formData.salesUserId"
              style="width: 50%"
              show-search
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearchSalesman"
            >
              <a-select-option
                v-for="item in dataSourceSalesman"
                :value="item.userId"
                :key="item.userId"
                @click="handleChangeSalesman(item)"
                >{{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="客户" prop="customerID">
            <a-select
              :value="formData.customerID"
              style="width: 50%"
              show-search
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearchClient"
            >
              <a-select-option
                v-for="item in dataSourceClient"
                :value="item.id"
                :key="item.id"
                @click="handleClickClient(item)"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <div class="goods">
      <div class="tit">商品信息</div>
      <div>
        <a-button class="editable-add-btn" @click="openAdd"> 新增 </a-button>
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :pagination="pagination"
          @change="handleTableChange"
          :rowKey="(record) => record.id"
        >
          <template slot="oper" slot-scope="text, record">
            <a-space size="middle">
              <a @click="openEidt(record)">编辑</a>
              <a @click="onDel(record)">删除</a>
            </a-space>
          </template>
          <template slot="isCustomized" slot-scope="text">
            {{ text ? "是" : "否" }}
          </template>
          <template slot="customAttach" slot-scope="text, record">
            <a-upload
              v-if="record.isCustomized"
              name="file"
              :multiple="true"
              @change="handleChangeUpload"
            >
              <a-button type="primary">
                <a-icon type="upload" /> 上传需求附件
              </a-button>
            </a-upload>
          </template>
          <template slot="customAttach" slot-scope="text, record">
            <a-upload
              v-if="record.isCustomized"
              name="file"
              :multiple="true"
              @change="handleChangeUpload"
            >
              <a-button type="primary">
                <a-icon type="upload" /> 上传需求附件
              </a-button>
            </a-upload>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal
      v-model="visible"
      :title="tit"
      :maskClosable="false"
      :width="600"
      :destroyOnClose="true"
      @ok="handleGoodsOk"
    >
      <edit-goods-modal :formEditData="formEditData" ref="edit" />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品名称",
    dataIndex: "goodName",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "购买数量",
    dataIndex: "orderNum",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "单价",
    dataIndex: "price",
    scopedSlots: { customRender: "price" },
  },
  {
    title: "交期",
    dataIndex: "deliveryData",
    scopedSlots: { customRender: "deliveryTime" },
  },
  {
    title: "是否定制",
    dataIndex: "isCustomized",
    scopedSlots: { customRender: "isCustomized" },
  },
  {
    title: "定制附件",
    dataIndex: "customAttach",
    scopedSlots: { customRender: "customAttach" },
  },
  {
    title: "操作",
    dataIndex: "oper",
    scopedSlots: { customRender: "oper" },
  },
];

import { getSalesOrder, updateSalesOrder } from "@/api/salesOrder";
import {
  getSalesOrderDetailList,
  createSalesOrderDetail,
  updateSalesOrderDetail,
  DeleteSalesOrderDetail,
} from "@/api/salesOrderDetail";
import { getCurrentCompanyUsersList } from "@/api/companyuser";
import { getListByCodeKeyPath } from "@/api/code.js";
import { getListCustomer } from "/src/api/customer.js";
import { getList } from "/src/api/purchase.js";
import EditGoodsModal from "./components/EditGoodsModal.vue";

export default {
  name: "SalesManageDetail",
  components: {
    EditGoodsModal,
  },
  data() {
    return {
      formData: {
        externalOrder: "",
        orderFrom: "",
        orderDate: undefined,
        salesman: "",
        client: "",
      },
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      count: 2,
      orderSource: [],
      dataSource: [],
      columns,
      dataSourceGoodsName: [],
      dataSourceSalesman: [],
      dataSourceClient: [],

      visible: false,
      tit: "添加商品",
      formEditData: {},

      rules: {
        sourceNo: [{ required: true, message: "必填项", trigger: "blur" }],
        sourceType: [{ required: true, message: "必填项", trigger: "change" }],
        orderDate: [{ required: true, message: "必填项", trigger: "change" }],
        salesUserId: [{ required: true, message: "必填项", trigger: "blur" }],
        customerID: [{ required: true, message: "必填项", trigger: "blur" }],
      },
    };
  },
  created() {
    getSalesOrder(this.$route.params.saleOrderId).then((res) => {
      this.formData = res;
    });
    getListByCodeKeyPath("HS_SourceType")
      .then((res) => {
        this.orderSource = res;
      })
      .catch(() => {
        this.$message.error("获取来源订单类型失败，请重试～！");
      });
    this.getGoodsList();
    this.getListCustomer();
    this.getCurrentCompanyUsersList();
    this.getSalesOrderDetailList();
  },
  methods: {
    getGoodsList(params) {
      if (!params) params = {};
      getList(params).then((res) => {
        this.dataSourceGoodsName = res.items;
      });
    },
    getListCustomer(param) {
      if (!param) param = {};
      getListCustomer(param).then((res) => {
        this.dataSourceClient = res.items;
      });
    },
    /**
     * 查询销售人员
     * @param (object) param 查询条件
     */
    getCurrentCompanyUsersList(param) {
      if (!param) param = {};
      param.isFilterInvitationStatus = true;
      param.maxResultCount = 10;
      param.skipCount = 0;
      getCurrentCompanyUsersList(param).then((res) => {
        this.dataSourceSalesman = res.items;
      });
    },

    getSalesOrderDetailList() {
      var parm = {
        salesID: this.$route.params.saleOrderId,
      };
      parm.maxResultCount = this.pagination.pageSize;
      parm.skipCount = (this.pagination.current - 1) * this.pagination.pageSize;
      getSalesOrderDetailList(parm).then((res) => {
        this.dataSource = res.items;
      });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getSalesOrderDetailList();
    },
    save() {
      this.$refs.basicForm.validate((valid) => {
        if (valid) {
          debugger;
          updateSalesOrder(this.formData.id, this.formData)
            .then(() => {
              this.$message.success("修改成功");
            })
            .catch(() => {
              this.$message.error("修改失败");
            });
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    openAdd() {
      this.tit = "添加商品";
      this.visible = true;
      this.formEditData = { salesID: this.$route.params.saleOrderId };
    },
    /** 保存商品信息 */
    handleGoodsOk() {
      this.$refs.edit.$refs["editGoodsForm"].validate((valid) => {
        if (valid) {
          var entity = this.$refs.edit.formData;
          if (!entity.id) {
            createSalesOrderDetail(entity).then((res) => {
              this.$message.success("新增销售商品成功");
              this.getSalesOrderDetailList();
            });
          } else {
            updateSalesOrderDetail(entity.id, entity).then((res) => {
              this.$message.success("修改销售商品成功");
              this.getSalesOrderDetailList();
            });
          }
          this.visible = false;
        } else {
          return false;
        }
      });
    },
    /** 编辑商品 */
    openEidt(record) {
      this.tit = "编辑商品";
      this.visible = true;
      this.formEditData = JSON.parse(JSON.stringify(record));
    },
    onDel(record) {
      var _this = this;
      this.$confirm({
        title: "确认删除" + record.goodName + "?",
        onOk() {
          DeleteSalesOrderDetail(record.id).then((res) => {
            _this.$message.success("删除销售商品成功");
            _this.getSalesOrderDetailList();
          });
        },
      });
    },
    handleSearchSalesman(searchText) {
      var param = {
        filter: searchText,
      };
      this.getCurrentCompanyUsersList(param);
    },
    handleChangeSalesman(e) {
      const value = e;
      this.formData.salesUserId = value.userId;
      this.formData.salesName = value.fullName;
    },
    handleSearchClient(value) {},
    handleClickClient(e) {
      const value = e;
      this.formData.customerID = value.id;
    },
    handleSearch(a, b) {
      console.log(a, b);
    },
    handleClick(entiy, record) {
      record.goodId = entiy.id;
      record.goodName = entiy.goodsName;
    },
    handleChangeUpload() {},
  },
};
</script>

<style lang="scss" scoped>
.oper {
  text-align: right;
}

.head {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
  margin-bottom: 16px;
}

.tit {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.basic {
  margin-bottom: 26px;
  .info {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 30%;
      margin-right: 16px;
    }
  }
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
