<template>
  <div class="wrap">
    <a-form-model
      ref="editGoodsForm"
      :model="formData"
      :rules="rules"
      v-bind="formItemLayout"
    >
      <a-form-model-item label="订单商品" prop="goodID">
        <a-select
          :key="formData.goodID"
          :defaultValue="formData.goodID"
          style="width: 80%"
          show-search
          :default-active-first-option="false"
          :filter-option="false"
          :not-found-content="null"
          placeholder="请输入订单商品"
          @search="handleSearch"
          @change="handleChangeGoodID"
        >
          <a-select-option
            v-for="item in dataSourceGoodsName"
            :value="item.id"
            :key="item.id"
            @click="handleClick(item)"
            >{{ item.goodsName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="购买数量" prop="orderNum">
        <a-input-number
          v-model="formData.orderNum"
          style="width: 80%"
          :min="0"
          :precision="0"
          placeholder="请输入购买数量"
        />
      </a-form-model-item>
      <a-form-model-item label="单价" prop="price">
        <a-input-number
          v-model="formData.price"
          style="width: 80%"
          :min="0"
          placeholder="请输入单价"
        />
      </a-form-model-item>
      <a-form-model-item label="期望交期" prop="deliveryData">
        <a-date-picker v-model="formData.deliveryData" style="width: 80%" />
      </a-form-model-item>
      <a-form-model-item label="是否定制" prop="isCustomized">
        <a-select
          :value="getisCustomized()"
          style="width: 80%"
          placeholder="请输入是否定制"
        >
          <a-select-option :value="0" :key="1" @click="setisCustomized('0')">
            否
          </a-select-option>
          <a-select-option :value="1" :key="2" @click="setisCustomized('1')">
            是
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-bind="{
          wrapperCol: {
            span: 18,
            offset: 6,
          },
        }"
      >
        <a-upload
          v-if="formData.isCustomized"
          name="file"
          :multiple="true"
          @change="handleChangeUpload"
        >
          <a-button type="primary">
            <a-icon type="upload" /> 上传需求附件
          </a-button>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getList } from "/src/api/purchase.js";
export default {
  data() {
    let goodIDValidator = (rule, value, callback) => {
      if (!this.formData.goodID) {
        callback("商品不能为空");
      }
      callback();
    };
    let deliveryDataValidator = (rule, value, callback) => {
      if (!this.formData.deliveryData) {
        callback("交期时间不能为空");
      }
      callback();
    };
    return {
      dataSourceGoodsName: [],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
      formData: {
        goodID: undefined,
        orderNum: "",
        price: "",
        deliveryData: undefined,
        isCustomized: false,
      },
      rules: {
        goodID: [{ validator: goodIDValidator, trigger: "blur" }],
        orderNum: [{ required: true, message: "必填项", trigger: "blur" }],
        price: [{ required: true, message: "必填项", trigger: "blur" }],
        deliveryData: [{ validator: deliveryDataValidator, trigger: "blur" }],
      },
    };
  },
  props: {
    formEditData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.getGoodsList();
  },
  methods: {
    getGoodsList(params) {
      if (!params) params = {};
      params.shelfStatus = 1;
      getList(params).then((res) => {
        this.dataSourceGoodsName = res.items;
      });
    },
    getisCustomized() {
      return this.formData.isCustomized == true ? 1 : 0;
    },
    setisCustomized(value) {
      this.formData.isCustomized = value == 0 ? false : true;
    },
    handleChangeGoodID(value) {
      this.formData.goodID = value;
    },
    handleClick(entiy) {
      this.formData.goodName = entiy.goodsName;
    },
    handleSearch(value) {
      var param = {
        filter: value,
      };
      this.getGoodsList(param);
    },
    handleChangeUpload() {
      console.log("handlechange");
    },
  },
  watch: {
    formEditData: {
      handler() {
        this.formData = this.formEditData;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  .ant-form-item {
    margin-bottom: 12px;
    >>> .ant-upload-list {
      width: 80%;
    }
  }
}
</style>
